import React from "react";

export default function Contact() {
  return (
    <div className="min-h-[84vh] w-screen flex-wrap justify-center md:mb-10">
      <div className="md:bg-gray-200 text-gray-800 text-center md:rounded-lg md:mt-5 pb-5 md:ml-5 md:mr-5">
        <div className="pl-5 pr-5 pt-5">
          <span className="text-2xl">Kontaktinformasjon</span>
          <br />
          <br />
          Takk for at du vurderer å kontakte Oceansong hundekennel! <br />
          Vi er glade for din interesse i våre labradorer, og vi er her for å
          hjelpe deg med det du lurer på. <br />
          <br />
          Du kan nå oss på telefon{" "}
          <span className="font-bold">
            <a href="callto:92258227">99258227</a>
          </span>{" "}
          eller e-post{" "}
          <a
            className="font-bold text-cyan-700"
            href="mailto:hildedh@icloud.com"
          >
            hildedh@icloud.com
          </a>
        </div>
      </div>
    </div>
  );
}
