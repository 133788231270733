import React from "react";
export const StateContext = React.createContext();

function StateProvider({ children }) {
  const [selected, setSelected] = React.useState(false);

  return (
    <StateContext.Provider value={{ selected, setSelected }}>
      {children}
    </StateContext.Provider>
  );
}
export default StateProvider;
