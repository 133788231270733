import React from "react";

export default function Footer() {
  return (
    <div className="flex flex-col flex-none h-24 absolute mb-0 w-full h-20 bg-gray-200 items-center justify-center shadow-2xl-inner pb-2">
      <p className="flex justify-center flex-none pt-1 pl-1 text-gray-800 w-3/4 text-center">
        Copyright: Oceansong.no ©
      </p>
      <p className="pt-1 pl-1 text-gray-700 text-sm w-4/5 md:w-3/4 text-center">
        Materialet på www.oceansong.no er omfattet av åndsverklovens
        bestemmeler. Enhver bruk av innhold og bilder er kun tillatt etter
        avtale.
      </p>
    </div>
  );
}
