import React from "react";
import { NavLink } from "react-router-dom";
import picture from "../img/kennel.png";
import picture2 from "../img/kennel2.png";
import picture3 from "../img/kennel3.png";

export default function About() {
  return (
    <div className="min-h-[84vh] flex-col">
      <div className="h-1/2 flex mb-4">
        <div className="flex flex-col md:flex-row md:mt-5 w-screen justify-evenly">
          <div className="w-screen bg-gray-200 text-gray-800 text-center md:rounded-lg min-w-[20vh] md:w-3/4 pt-2 md:pt-5 pl-5 pr-5 mr-5 pb-3 md:items-center">
            <span className="text-2xl">Om oss</span>
            <br />
            <br />
            Vi er et voksent ektepar med en lidenskap for labradorer som har
            drevet kenneldrift i flere år. Gjennom årene har vi opparbeidet oss
            verdifull erfaring og kunnskaper innen oppdrett av labradorer.{" "}
            <br />
            <div className="flex flex md:flex-row justify-around pt-4 lg:pt-10 lg:pb-10">
              <img
                className="md:w-[65%] md:border-gray-500 md:rounded-lg pb-2 lg:pb-0 lg:w-auto lg:max-h-80 lg:overflow-y-hidden"
                src={picture}
                alt="Kennel"
              />

              <img
                className="hidden lg:block md:border-gray-500 lg:rounded-lg lg:pb-0 lg:w-auto lg:max-h-80 lg:overflow-y-hidden"
                src={picture3}
                alt="Kennel"
              />

              <img
                className="md:w-[30%] md:border-gray-500 md:rounded-lg pb-2 lg:pb-0 hidden md:block lg:w-auto lg:max-h-80 lg:overflow-y-hidden"
                src={picture2}
                alt="Kennel"
              />
            </div>
            <br />
            Våre hunder er våre stoltheter, og vi har oppnådd gode resultater i
            utstillinger som bekrefter de gode genene som finnes i vår flokk.
            Vår hovedprioritet i avlsarbeidet er å avle frem friske og sunne
            hunder. Vi går nøye gjennom avlsprosessen for å sikre at vi bidrar
            til å forbedre rasens helse og velvære. Vi ønsker også å inkludere
            nye friske gener i avlen, når det er mulig, for å styrke rasens
            generelle helse og trivsel. <br />
            <br />
            Labradorer er vår utvalgte rase, og vi har virkelig falt for deres
            fantastiske egenskaper. Deres lekne og vennlige natur gjør dem til
            fantastiske følgesvenner og familiehunder. Vi er stadig imponert
            over deres intelligens, lojalitet og evnen til å tilpasse seg ulike
            situasjoner. For våre hunder er det viktig å få utløp for sin energi
            og nysgjerrighet. Derfor lar vi dem løpe fritt i et stort og
            inngjerdet område hvor de kan utforske og leke. Dette bidrar til å
            holde dem sunne, glade og sosialiserte. <br />
            <br />
            Vi er alltid tilgjengelige for spørsmål og samtaler om våre hunder
            og vårt oppdrett. Kontakt oss gjerne på telefon eller e-post hvis du
            har spørsmål om rasen, avlsprosessen eller hvis du ønsker å vite mer
            om våre valpeplaner. Kontaktinformasjon finner du{" "}
            <NavLink className="text-cyan-700" to="/contact">
              her.
            </NavLink>
            <br />
            <br />
            Vårt ønske er alltid å finne gode og kjærlige hjem til våre valper.
            Derfor forbeholder vi oss retten til å velge hvem som får kjøpe en
            valp fra vår kennel. Vi ønsker at alle våre hunder skal bli en del
            av en omsorgsfull familie som vil gi dem den kjærligheten og
            omsorgen de fortjener. <br />
            <br />
            Med vår mangeårige erfaring, er vi glade for å kunne dele tips og
            triks om hundeoppdragelse og omsorg. Vi er alltid her for å gi
            støtte og veiledning til våre valpekjøpere gjennom hele hundens liv.
            Du kan følge oss på Facebook{" "}
            <a
              href="https://www.facebook.com/groups/557803981444025"
              className="text-cyan-700"
            >
              {" "}
              her.
            </a>
            <br />
            <img className="md:hidden pt-4" src={picture2} alt="Kennel" />
            <br />
            Takk for at du besøker oss, og vi håper du blir fornøyd med en hund
            fra Oceansong hundekennel. Vi ser frem til å dele vår kjærlighet til
            labradorer med deg og ønsker deg velkommen til vår hundefamilie.
          </div>
        </div>
      </div>
    </div>
  );
}
