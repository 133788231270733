import React from "react";
import { NavLink } from "react-router-dom";

export default function Dog({ item }) {
  const linkAdress = `/dogs/${item.slug}`;

  return (
    <div className="w-2/3 sm:w-1/3 mr-4 ml-4 md:w-1/4 lg:w-1/5 md:ml-0 md:mr-4">
      <NavLink to={linkAdress}>
        {item?.mainImg && (
          <img src={item.mainImg} alt="hund" className="rounded-lg" />
        )}
        <figcaption className="text-center sm:text-sm pt-2">
          {item.realName}
        </figcaption>
        <figcaption className="text-center pb-6">{item.name}</figcaption>
      </NavLink>
    </div>
  );
}
