import React from "react";

export default function PostContent({ content }) {
  const pictures = content;

  return (
    <div className="flex flex-col w-screen pl-5 pr-5 justify-center items-center">
      <div className="flex flex-wrap w-screen ml-0 justify-around h-max-[30rem] overflow-y-hidden">
        {pictures &&
          Object.values(pictures).map((pic, index) => {
            const link = `${process.env.PUBLIC_URL}${pic}`;

            return (
              <div key={index}>
                <img src={link} alt="post" />
              </div>
            );
          })}
      </div>
    </div>
  );
}
