import React from "react";
import { NavLink } from "react-router-dom";

export default function Cards({ item, id }) {
  return (
    <div
      key={id}
      className="grow basis-80 min-h-18 py-1 px-2 mt-4 mx-2 bg-slate-200 rounded border-2 border-cyan-700 text-center"
    >
      <NavLink to={`/post/${item.slug}`}>
        <p className="">{item.title}</p>
        <p className="text-sm">{item.date}</p>
        <p className="text-sm">Les mer</p>
      </NavLink>
    </div>
  );
}
