import React from "react";
import Dog from "./Dog";
import NotFound from "./NotFound";
import Spinner from "./Spinner";
import DogContent from "./DogContent";
import { v4 as uuidv4 } from "uuid";

export default function SingleDog({ location, slug }) {
  const [dogs, setDogs] = React.useState();
  const [slugsInUse, setSlugsInUse] = React.useState(new Set());
  const [categoryInUse, setCategoryInUse] = React.useState(new Set());
  const [loading, setLoading] = React.useState("loading");

  async function getNews() {
    const response = await fetch("/doginfo.json");
    const data = await response.json();
    setDogs(data);
  }

  React.useEffect(() => {
    getNews();
  }, []);

  //find categories and slugs in use
  React.useEffect(() => {
    dogs?.forEach((item) => {
      const newSlug = item["slug"];
      const newCategory = item["category"];
      setSlugsInUse((prevTmp) => new Set([...prevTmp, newSlug]));
      setCategoryInUse((prevTmp) => new Set([...prevTmp, newCategory]));
      setLoading("idle");
    });
  }, [dogs]);

  //Sort by category
  if (categoryInUse && categoryInUse?.has(slug)) {
    return (
      <div className="flex flex-wrap w-screen pt-10 pb-8 justify-around h-max-[30rem] overflow-y-hidden">
        {dogs?.map((item) => {
          if (item.category === slug) {
            return <Dog item={item} key={uuidv4()} />;
          }
        })}
      </div>
    );
  }

  //Sort by name
  if (slugsInUse && slugsInUse?.has(slug)) {
    return (
      <div className="flex flex-wrap w-screen justify-around h-max-[30rem] overflow-y-hidden">
        {dogs?.map((item) => {
          if (item.slug === slug) {
            return (
              <div key={item.slug}>
                <DogContent content={item.content} slug={item.slug} />
                <div className="sm:pt-8">
                  <div className="w-screen mt-0 h-0.5 bg-cyan-700 rounded-lg sm:hidden" />
                  <p className=" mt-4 mb-4 text-center sm:hidden">
                    {item.realName}
                  </p>
                  <div className="w-screen mt-0 h-0.5 bg-cyan-700 rounded-lg sm:hidden" />
                </div>
              </div>
            );
          }
        })}
      </div>
    );
  }

  return <div>{loading === "loading" ? <Spinner /> : <NotFound />}</div>;
}
