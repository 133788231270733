import React from "react";
import { v4 as uuidv4 } from "uuid";
import Cards from "./Cards";
import { useParams } from "react-router-dom";
import Spinner from "./Spinner";
import NotFound from "./NotFound";
import PostContent from "./PostContent";

export default function Post() {
  const [posts, setPosts] = React.useState([]);
  const [slugsInUse, setSlugsInUse] = React.useState(new Set());
  const [loading, setLoading] = React.useState("loading");
  const { slug } = useParams();

  async function getPosts() {
    const response = await fetch("/postFeed.json");
    const data = await response.json();
    setPosts(data);
  }

  React.useEffect(() => {
    getPosts();
  }, []);

  React.useEffect(() => {
    posts?.forEach((item) => {
      const newSlug = item["slug"];
      setSlugsInUse((prevTmp) => new Set([...prevTmp, newSlug]));
      setLoading("idle");
    });
  }, [posts]);

  //Sort by slug
  if (slugsInUse && slugsInUse?.has(slug)) {
    return (
      <div className="flex flex-wrap w-screen justify-around h-max-[30rem] overflow-y-hidden">
        {posts?.map((item) => {
          if (item.slug === slug) {
            return (
              <div key={item.slug} className="bg-slate-200">
                <p className="py-2 text-center font-bold">{item.title}</p>
                {item.content && (
                  <p className="py-2 text-center">{item.content}</p>
                )}
                <PostContent content={item.pictures} />
              </div>
            );
          }
        })}
      </div>
    );
  }

  if (slug === undefined) {
    return (
      <div className="bg-white min-h-[84vh] flex flex-wrap content-start pb-2">
        {posts?.map((item) => (
          <Cards item={item} id={uuidv4()} key={item.slug} />
        ))}
      </div>
    );
  }

  return (
    <div className="pb-4">
      {loading === "loading" ? <Spinner /> : <NotFound />}
    </div>
  );
}
