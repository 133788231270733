import React from "react";
import unghunder from "../img/unghund.jpeg";
import pensjonister from "../img/pensjonist.png";
import hanner from "../img/hanner.png";
import tisper from "../img/tisper.png";
import SingleDog from "./SingleDog";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";

export default function Dogs({ location }) {
  const { slug } = useParams();

  return (
    <div className="min-h-[84vh] flex-col">
      {slug ? (
        <SingleDog slug={slug} />
      ) : (
        <>
          <div className="h-1/3 flex mb-4 w-screen">
            <div className="flex flex-col md:flex-row md:mt-5 w-screen justify-evenly">
              <span className="text-xl pt-2 pb-2 font-bold text-center">
                Våre hunder
              </span>
              <div className="w-screen bg-gray-200 mt-2 mb-2 text-gray-800 text-center md:rounded-lg min-w-[20vh] md:w-3/4 pt-2 md:pt-5 pl-5 pr-5 mr-5 pb-3 md:items-center"></div>
            </div>
          </div>
          <div className="flex flex-col w-screen md:pt-15 pl-5 pr-5 justify-center items-center text-left">
            <p>
              Her finner du oversikt over hunder tilknyttet vår kennel. Velg
              kategori for å se flere hunder.
            </p>

            <div className="flex flex-wrap w-screen ml-4 mr-4 pt-10 pb-8 justify-around h-max-[30rem] overflow-y-hidden">
              <div className="w-2/3 sm:w-1/2 sm:pl-4 sm:pr-4 md:w-1/5 md:pl-0 md:pr-0">
                <NavLink to="/dogs/male">
                  <img src={hanner} alt="hund" className="rounded-lg" />
                  <figcaption className="text-center pt-2 pb-6">
                    Hanner
                  </figcaption>
                </NavLink>
              </div>
              <div className="w-2/3 sm:w-1/2 sm:pl-4 sm:pr-4 md:w-1/5 md:pl-0 md:pr-0">
                <NavLink to="/dogs/female">
                  <img src={tisper} alt="hund" className="rounded-lg" />
                  <figcaption className="text-center pt-2 pb-6">
                    Tisper
                  </figcaption>
                </NavLink>
              </div>
              <div className="w-2/3 sm:w-1/2 sm:pl-4 sm:pr-4 md:w-1/5 md:pl-0 md:pr-0">
                <NavLink to="/dogs/seniors">
                  <img src={pensjonister} alt="hund" className="rounded-lg" />
                  <figcaption className="text-center pt-2 pb-6">
                    Pensjonister
                  </figcaption>
                </NavLink>
              </div>
              <div className="w-2/3 sm:w-1/2 sm:pl-4 sm:pr-4 md:w-1/5 md:pl-0 md:pr-0">
                <NavLink to="/dogs/juniors">
                  <img src={unghunder} alt="hund" className="rounded-lg" />
                  <figcaption className="text-center pt-2 pb-6">
                    Unghunder
                  </figcaption>
                </NavLink>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
