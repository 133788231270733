import React from "react";
import Lottie from "lottie-react";
import NotFoundDog from "../animations/dog_animation.json";

export default function NotFound() {
  return (
    <div className="flex flex-col md:flex-row min-h-[84vh] w-full bg-black justify-center">
      <div className="flex h-3/5 md:h-2/5 w-screen md:w-2/5 bg-black rounded-lg md:justify-center md:items-center">
        <Lottie
          className="flex text-center"
          animationData={NotFoundDog}
          loop={true}
        />{" "}
      </div>
      <p className="text-black rounded-lg ml-4 mr-4 md:ml-4 md:mr-4 bg-white h-1/5 mt-20 p-2">
        Oi! Her var det ikke noe innhold. Det kan skyldes mange årsaker! <br />
        <br /> Her har du en{" "}
        <a
          className="text-cyan-800 hover:text-white rounded-md hover:bg-cyan-700 pl-1.5 pr-1.5 pt-1 pb-1"
          href="/"
        >
          lenke
        </a>{" "}
        til startsiden{" "}
      </p>
    </div>
  );
}
