import React from "react";
import { NavLink } from "react-router-dom";
import { StateContext } from "./Provider/StateProvider";

export default function ContactButton() {
  const { setSelected } = React.useContext(StateContext);

  return (
    <div>
      <div className="fixed right-0 bottom-0 mr-3 md:mr-16 lg:mr-36 mb-10 md:mb-28 font-bold py-2 px-4 btn bg-cyan-700 rounded text-white hover:bg-cyan-900 ring-2 ring-cyan-600">
        <NavLink
          to="/contact"
          onClick={() => {
            setSelected((prev) => false);
          }}
        >
          {" "}
          Kontakt oss{" "}
        </NavLink>
      </div>
    </div>
  );
}
