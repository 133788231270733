import React, { useEffect } from "react";
import Logo from "../img/mainLogo.jpeg";
import { NavLink } from "react-router-dom";
import nrk from "../img/nrk.jpeg";
import royal from "../img/royalcanin.png";
import fci from "../img/fci.png";
import { v4 as uuidv4 } from "uuid";

export default function Something() {
  const [news, setNews] = React.useState([]);
  const initialCount = 5;
  const [newsCount, setNewsCount] = React.useState(initialCount);
  const [show, setShow] = React.useState(news.slice(0, newsCount));
  const [showMoreNews, setShowMoreNews] = React.useState(true);

  async function getNews() {
    const response = await fetch("/newsFeed.json");
    const data = await response.json();
    setNews(data);
  }

  //get the data from data.json
  React.useEffect(() => {
    getNews();
  }, []);

  //set the show state to contain first elements from News, and up to number in newsCount, refreshes when count is updated
  useEffect(() => {
    setShow(news?.slice(0, newsCount));
  }, [news, newsCount]);

  //show load more news button if news.length is more than zero, and larger then show.length
  useEffect(() => {
    if (news.length === 0) {
      return;
    }
    if (show.length >= news.length) {
      setShowMoreNews(false);
    }
  }, [show]);

  //function for adjusting number that determines how many news that will show. Reprecented by the load more news button.
  const addNewsCount = () => {
    if (show.length < news.length) {
      setNewsCount((prev) => prev + initialCount);
    } else {
      return;
    }
  };

  return (
    <div className="min-h-[84vh] flex-col">
      <div className="h-1/2 flex items-start">
        <div className="flex flex-col md:flex-row justify-center md:mt-5 w-screen justify-evenly">
          <div className="h-1/2 w-screen md:w-1/3 md:rounded-lg">
            <img
              className="md:w-3/4 md:border-black md:border-4 md:rounded-lg"
              src={Logo}
              alt="Kennel logo"
            />
          </div>
          <div className="w-screen bg-gray-200 text-gray-800 text-center md:rounded-lg min-w-[20vh] md:w-1/2 pt-2 md:pt-5 pl-5 pr-5 mr-5 pb-3 md:items-center">
            <span className="text-2xl">
              Velkommen til Oceansong hundekennel!
            </span>{" "}
            <br />
            <br />
            Vi er stolte av å være en anerkjent labradorkennel som holder til i
            hjertet av Trondheim og det vakre landskapet sør for byen. Vår
            lidenskap for hunder og spesielt labradorer har drevet oss til å
            etablere en unik kennel hvor omsorg, kjærlighet og profesjonalitet
            går hånd i hånd. <br />
            <br />
            Vi setter dyrevelferd i høysetet og sørger for at våre hunder lever
            et aktivt og givende liv. Vi ønsker å opprettholde og forbedre
            rasens gode egenskaper. Les mer om oss{" "}
            <NavLink to="/about" className="text-cyan-700">
              her
            </NavLink>{" "}
            <br />
            <br />
            På nettsiden vår deler vi med stolthet og glede nyheter og
            informasjon om våre labradorer og inviterer deg til å bli med på
            eventyret med oss. Utforsk bildene og historiene bak våre hunder, og
            lær mer om deres personligheter og unike egenskaper. <br />
            <br /> Besøk Oceansong hundekennel, og opplev kjærligheten og
            dedikasjonen vi har for denne fantastiske rasen. Bli en del av vår
            store hundefamilie, og la oss sammen skape uforglemmelige minner med
            våre lodne venner. Vi ser frem til å dele vår lidenskap for
            labradorer med deg! <br />
          </div>
        </div>
      </div>
      <div>
        <div className="w-screen h-0.5 bg-cyan-600 md:mt-20"></div>
        <h1 className="text-cyan-700 text-2xl flex justify-center mb-2 mt-2 font-bold">
          Nyheter
        </h1>
        <div className="w-screen h-0.5 bg-cyan-600 mb-2"></div>
        {show &&
          show?.map((item) => {
            return (
              <div
                className="flex flex-col justify-start items-center md:pt-2"
                key={uuidv4()}
              >
                <p className="text-sm text-gray-500 m-2">{item.date}</p>
                <h1 className="font-medium text-center">{item.title}</h1>

                {item?.mainImg && (
                  <img src={item.mainImg} alt="bilde" className="h-1/5 w-1/5" />
                )}
                {item?.link ? (
                  <NavLink className="text-cyan-700" to={item.link}>
                    {item.description}
                  </NavLink>
                ) : (
                  <p>{item.description}</p>
                )}
                <div className="w-1/2 md:w-1/4 h-0.5 bg-cyan-700 rounded-lg m-4"></div>
              </div>
            );
          })}

        <div className="w-100 flex h-10 mt-8 mb-2 relative justify-center items-center">
          {showMoreNews ? (
            <button
              className="flex flex-none relative h-4 items-center w-1/7 py-0 px-2 mb-4 bg-cyan-700 rounded-sm text-white text-sm hover:bg-cyan-900 ring-2 ring-cyan-600"
              onClick={() => {
                addNewsCount();
              }}
            >
              Last inn mer
            </button>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="flex w-full justify-center">
        <div className="flex bg-gray-200 rounded w-full md:w-2/3 h-0.5 mb-5 mt-5"></div>
      </div>
      <div className="flex w-screen h-24 justify-center">
        <div className="relative flex h-16 justify-center z-[-1]">
          <img alt="royal canin logo" src={royal} />
        </div>
      </div>
      <div className="flex w-screen h-20 justify-center mb-5">
        <div className="relative flex h-18 justify-center z-[-1]">
          <img
            alt="The Fédération Cynologique Internationale logo"
            src={fci}
            className="w-1/3 sm:1/2"
          />
          <img
            alt="norsk retrieverklubb logo"
            src={nrk}
            className="w-1/3 sm:1/2"
          />
        </div>
      </div>
    </div>
  );
}
