import React from "react";
import Navbar from "./components/Navbar";
import "./index.css";
import { Route, Routes, useLocation } from "react-router-dom";
import StateProvider from "./components/Provider/StateProvider";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import NotFound from "./components/NotFound"; // eslint-disable-line no-use-before-define
import Post from "./components/Post";
import Pictures from "./components/Pictures";
import ContactButton from "./components/ContactButton";
import Footer from "./components/Footer";
import Dogs from "./components/Dogs";
import CookieConsent, {
  getCookieConsentValue,
  Cookies,
} from "react-cookie-consent";
import { initGA } from "./ga-utils";

function App() {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  React.useEffect(() => {
    const isConsent = getCookieConsentValue();
    if (isConsent === "true") {
      handleAcceptCookie();
    }
  }, []);

  const handleAcceptCookie = () => {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
      initGA(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
    }
  };

  const handleDeclineCookie = () => {
    //remove google analytics cookies
    Cookies.remove("_ga");
    Cookies.remove("_gat");
    Cookies.remove("_gid");
  };

  return (
    <StateProvider>
      <Navbar />
      <Routes location={location} key={location.pathname}>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" exact element={<About />} />
        <Route path="/dogs" exact element={<Dogs location={location} />} />
        <Route
          path="/dogs/:slug"
          exact
          element={<Dogs location={location} />}
        />
        <Route path="/post/:slug" exact element={<Post />} />
        <Route path="/post" exact element={<Post />} />
        <Route path="/contact" exact element={<Contact />} />
        <Route path="/pictures" exact element={<Pictures />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
      <ContactButton />
      <CookieConsent
        enableDeclineButton
        onAccept={handleAcceptCookie}
        onDecline={handleDeclineCookie}
        declineButtonText="Kun nødvendige"
        buttonText="Godta alle nettkapsler"
      >
        Denne nettsiden bruker nettkapsler for analytiske formål. Ved å godta
        kun bruk av nødvendige nettkapsler lagres en nettkapsel for å huske ditt
        valg. Nettkapsler hjelper oss forbedre opplevelsen din. Ved bruk av
        nettsiden samtykker du i bruk av nettkapsler som beskrevet.
      </CookieConsent>
    </StateProvider>
  );
}

export default App;
