import React from "react";
import "../index.css";
import { NavLink } from "react-router-dom";
import { StateContext } from "./Provider/StateProvider";

export default function Navbar() {
  const { selected, setSelected } = React.useContext(StateContext);

  return (
    <div>
      <div className="flex flex-row h-12 bg-black md:w-full drop-shadow-md fixed top-0 left-0 right-0">
        <div className="flex flex-row flex-none text-3xl text-white w-1/3 justify-start self-end pl-2 md:pl-10 lg:pl-20">
          <NavLink
            to="/"
            className="font-MyFont flex-none text-white"
            onClick={() => {
              setSelected((prev) => false);
            }}
          >
            Oceansong Kennel
          </NavLink>
        </div>
        <div className="flex flex-row w-full justify-end self-end">
          <div className="flex h-8 flex-row w-2/5 justify-around mr-5 invisible md:visible">
            <NavLink
              to="/dogs"
              className="text-gray-400 hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
            >
              Hunder
            </NavLink>

            <NavLink
              to="/about"
              className="text-gray-400 flex-none hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
            >
              Om oss
            </NavLink>

            <NavLink
              to="/post"
              className="text-gray-400 hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
            >
              Informasjon
            </NavLink>
          </div>
        </div>
        {!selected ? (
          <div
            className="flex flex-col h-8 w-12 my-1 border border-cyan-700 border-2 rounded-md self-center justify-around items-center pt-1 pb-1 mr-2 md:invisible"
            onClick={() => {
              setSelected((prev) => !prev);
            }}
          >
            <div className="flex bg-cyan-700 w-3/4 h-0.5 rounded-lg"></div>
            <div className="flex bg-cyan-700 w-3/4 h-0.5 rounded-lg"></div>
            <div className="flex bg-cyan-700 w-3/4 h-0.5 rounded-lg"></div>
          </div>
        ) : (
          <div
            className="flex flex-col h-8 w-12 my-1 border border-cyan-700 border-2 rounded-md self-center justify-around items-center pt-1 pb-1 rotate-90 mt-1 mr-2 md:invisible"
            onClick={() => {
              setSelected((prev) => !prev);
            }}
          >
            <div className="flex absolute bg-cyan-700 w-3/4 h-0.5 rounded-lg rotate-[-45deg]"></div>
            <div className="flex absolute bg-cyan-700 w-3/4 h-0.5 rounded-lg rotate-45"></div>
          </div>
        )}
      </div>
      <div
        className={`sidebar ${
          selected ? "sidebar-show" : " "
        } border-l-2 border-b-2 border-cyan-700 rounded-bl-lg items-center md:invisible`}
      >
        <ul>
          {" "}
          <NavLink
            to="/dogs"
            className="text-white hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
            onClick={() => {
              setSelected((prev) => !prev);
            }}
          >
            Hunder
          </NavLink>
        </ul>
        <div className="w-2/3 bg-cyan-700 rounded-md h-1"></div>
        <ul>
          <NavLink
            to="/about"
            onClick={() => {
              setSelected((prev) => !prev);
            }}
            className="text-white hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
          >
            Om oss
          </NavLink>
        </ul>
        <div className="w-2/3 bg-cyan-700 rounded-md h-1"></div>
        <ul>
          <NavLink
            to="/post"
            onClick={() => {
              setSelected((prev) => !prev);
            }}
            className="text-white hover:bg-cyan-800 my-1 px-1 border-cyan-700 hover:text-white hover:border hover:rounded"
          >
            Informasjon
          </NavLink>
        </ul>
      </div>
      <div className="h-12"></div>
    </div>
  );
}
