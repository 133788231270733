import React from "react";
import { Loader } from "react-feather";

function Spinner({ color = "black", size = 24, opacity = 0.5, speed = 1200 }) {
  return (
    <div className="flex justify-center mt-10">
      <span
        className="wrapper"
        style={{
          opacity,
          "--speed": `${speed}ms`,
          width: size,
          height: size,
        }}
      >
        <Loader color={color} size={size} />
      </span>
    </div>
  );
}

export default Spinner;
